import { SIDEBAR_TOGGLED, SLIDER_TOGGLED, SPACE_SECTION_TOGGLED, TIME_SECTION_TOGGLED } from './types';

export const toggleSlider = (sliderOpen) => ({
  type: SLIDER_TOGGLED,
  sliderOpen,
});

export const sidebarOpen = (isSidebarOpen) => ({
  type: SIDEBAR_TOGGLED,
  isSidebarOpen,
});

export const toggleTimeSection = (isTimeSectionActive) => ({
  type: TIME_SECTION_TOGGLED,
  isTimeSectionActive,
});

export const toggleSpaceSection = (isSpaceSectionActive) => ({
  type: SPACE_SECTION_TOGGLED,
  isSpaceSectionActive,
});

export const openSlider = (status) => (dispatch) => dispatch(toggleSlider(status));

export const openSidebar = (isSidebarOpen) => (dispatch) => dispatch(sidebarOpen(isSidebarOpen));
