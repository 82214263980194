import { deleteDocument, getDocument, getDocuments, newDocument, setDocument } from './firestore';

import { getPrimaryLocationDoc } from './locations';

export const getOrganizationDevices = (organizationId, orderBy = [], limit = null, startAfter = null) =>
  getDocuments({
    path: `organizations/${organizationId}/devices`,
    orderBy: [{ field: 'name', direction: 'asc' }, ...orderBy],
    limit,
    startAfter,
  });

export const getOrganizationDevice = (organizationId, deviceId) =>
  getDocument({
    path: `organizations/${organizationId}/devices/${deviceId}`,
  });

export const newOrganizationDevice = (organizationId) =>
  newDocument({ path: `organizations/${organizationId}/devices` });

export const setOrganizationDevice = async (organizationId, data) => {
  if (!data.organization) data.organization = organizationId;

  if (!data.location) {
    const locationDoc = await getPrimaryLocationDoc(organizationId);
    if (locationDoc?.exists) data.location = locationDoc.id;
  }
  setDocument({
    path: `organizations/${organizationId}/devices/${data.id}`,
    data,
  });
};

export const deleteOrganizationDevice = (organizationId, id) =>
  deleteDocument({
    path: `organizations/${organizationId}/devices/${id}`,
  });
