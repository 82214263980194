import { Button, ButtonLink, Dialog } from '@wonderschool/common-base-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import KinderConnectCredentials from './credentials';

interface CredentialsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CredentialsDialog: React.FC<CredentialsDialogProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const handleLoginHelpModal = () => {
    setIsHelpModalOpen(true);
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} extraClasses="text-gray-800">
      <Dialog.Panel>
        <Dialog.Title>{t('KinderConnect')}</Dialog.Title>
        {!isHelpModalOpen && (
          <>
            <Dialog.Description>{t('apps.kinderConnect.manageLoginDescription')}</Dialog.Description>
            <div className="pt-4">
              <KinderConnectCredentials onSave={onClose} />
            </div>
            <ButtonLink
              onClick={handleLoginHelpModal}
              content={t('apps.kinderConnect.needHelp')}
              extraClasses="w-full text-center mt-4"
            />
          </>
        )}
        {/* Help Modal */}
        {isHelpModalOpen && (
          <>
            <Dialog.Description>{t('apps.kinderConnect.description2')}</Dialog.Description>
            <Dialog.Description>{t('apps.kinderConnect.description3')}</Dialog.Description>
            <div className="mt-">
              <Button
                primary
                label={t('common.backToLogin')}
                onClick={() => setIsHelpModalOpen(false)}
                extraClasses="w-full mt-8"
              />
            </div>
          </>
        )}
      </Dialog.Panel>
    </Dialog>
  );
};
