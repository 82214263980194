import {
  Breadcrumbs,
  Button,
  MainContentLayout,
  Money5Icon,
  ReportsIcon,
  SendIcon,
  StudentIcon,
  Tab,
  TabGroup,
  User2Icon,
} from '@wonderschool/common-base-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { getTitlePrefix } from '../../../config/env';
import { useOrganization } from '../../../hooks/useOrganizations';
import KinderConnectIcon from '../assets/kinder-systems.svg';
import { CredentialsDialog } from './CredentialsDialog';
import { KinderConnectStudentMappings } from './StudentMappings';
import { KinderConnectReports } from './reports';

export const KinderConnectPage = () => {
  const { t } = useTranslation();
  const title = 'KinderConnect';
  const { isKinderSystemsEnabled } = useOrganization();
  const [isCredentialsDialogOpen, setIsCredentialsDialogOpen] = useState(false);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
    </Helmet>
  );

  const handleCloseModal = () => {
    setIsCredentialsDialogOpen(false);
  };

  return (
    <>
      <MainContentLayout>
        {renderPageHead()}
        <Breadcrumbs
          items={[
            {
              current: false,
              href: '/appstore',
              label: 'Apps',
            },
            {
              current: true,
              href: '/kinderconnect',
              label: 'KinderConnect',
            },
          ]}
        />
        <div className="container py-10">
          <div className="grid grid-cols-1 gap-4 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex items-center gap-x-6">
              <div className="h-16 w-16 place-content-center rounded-full ring-1 ring-gray-900/10">
                <img className="mx-auto" src={KinderConnectIcon} alt="KinderConnect" />
              </div>
              {/*  eslint-disable-next-line i18next/no-literal-string */}
              <h1 className="my-auto text-3xl font-bold text-gray-900">KinderConnect</h1>
            </div>
            <div className="flex flex-col gap-x-4 sm:gap-x-6 lg:items-end">
              {isKinderSystemsEnabled ? (
                <Button
                  label={t('apps.kinderConnect.manageLogin')}
                  id="kinderconnect-manage-login"
                  preIcon={<User2Icon className="h-5 w-5" />}
                  onClick={() => setIsCredentialsDialogOpen(true)}
                />
              ) : (
                <Button
                  label={t('apps.kinderConnect.connectYourAccount')}
                  id="kinderconnect-connect-your-account"
                  primary
                  onClick={() => setIsCredentialsDialogOpen(true)}
                />
              )}
            </div>
          </div>
          {!isKinderSystemsEnabled && (
            <div className="grid gap-6 lg:grid-cols-2">
              <div className="mt-8 flex flex-col gap-x-4 sm:gap-x-6">
                <div className="text-xl font-bold text-gray-800">{t('apps.kinderConnect.title1')}</div>
                <div className="mt-6 text-sm font-normal text-gray-800">{t('apps.kinderConnect.description1')}</div>
                <div className="mt-8 text-xl font-bold text-gray-800">{t('apps.kinderConnect.title2')}</div>
                <div className="mt-6 text-sm font-normal text-gray-800">{t('apps.kinderConnect.description2')}</div>
                <div className="mt-6 text-sm font-normal text-gray-800">{t('apps.kinderConnect.description3')}</div>
              </div>

              <div className="mt-8 flex flex-col gap-x-4 rounded-md border border-gray-300 p-6 sm:gap-x-6">
                <div className="text-xl font-bold text-gray-800">{t('apps.kinderConnect.flow.title')}</div>
                <div className="flow-root">
                  <ul role="list" className="-mb-8 mt-8">
                    <li key="1">
                      <div className="relative pb-8">
                        <span aria-hidden="true" className="absolute left-6 top-4 -ml-px h-full w-0.5 bg-navy-200" />
                        <div className="relative flex space-x-3">
                          <div>
                            <span className="flex size-12 items-center justify-center rounded-full bg-yellow-200">
                              <StudentIcon aria-hidden="true" className="size-6 text-yellow-950" />
                            </span>
                          </div>
                          <div className="flex min-w-0 flex-1 items-center justify-between space-x-4">
                            <div>
                              <p className="text-sm font-normal text-gray-800">{t('apps.kinderConnect.flow1')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li key="2">
                      <div className="relative pb-8">
                        <span aria-hidden="true" className="absolute left-6 top-4 -ml-px h-full w-0.5 bg-navy-200" />
                        <div className="relative flex space-x-3">
                          <div>
                            <span className="flex size-12 items-center justify-center rounded-full bg-navy-50">
                              <SendIcon aria-hidden="true" className="size-6 text-blue-950" />
                            </span>
                          </div>
                          <div className="flex min-w-0 flex-1 items-center justify-between space-x-4">
                            <div>
                              <p className="text-sm font-normal text-gray-800">{t('apps.kinderConnect.flow2')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li key="3">
                      <div className="relative pb-8">
                        <span aria-hidden="true" className="absolute left-6 top-4 -ml-px h-full w-0.5 bg-navy-200" />
                        <div className="relative flex space-x-3">
                          <div>
                            <span className="flex size-12 items-center justify-center rounded-full bg-navy-50">
                              <ReportsIcon aria-hidden="true" className="size-6 text-blue-950" />
                            </span>
                          </div>
                          <div className="flex min-w-0 flex-1 items-center justify-between space-x-4">
                            <div>
                              <p className="text-sm font-normal text-gray-800">{t('apps.kinderConnect.flow3')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li key="4">
                      <div className="relative pb-8">
                        <div className="relative flex space-x-3">
                          <div>
                            <span className="flex size-12 items-center justify-center rounded-full bg-navy-50">
                              <Money5Icon aria-hidden="true" className="size-6 text-blue-950" />
                            </span>
                          </div>
                          <div className="flex min-w-0 flex-1 items-center justify-between space-x-4">
                            <div>
                              <p className="text-sm font-normal text-gray-800">{t('apps.kinderConnect.flow4')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="mt-1 text-sm font-normal text-gray-800"></div>
              </div>
            </div>
          )}
          {isKinderSystemsEnabled && (
            <div className="flex flex-col gap-x-4 pt-10 sm:gap-x-6">
              <TabGroup>
                <Tab label={t('Students')}>
                  <KinderConnectStudentMappings />
                </Tab>
                <Tab label={t('Reports')}>
                  <KinderConnectReports />
                </Tab>
              </TabGroup>
            </div>
          )}
          {isCredentialsDialogOpen && <CredentialsDialog isOpen={isCredentialsDialogOpen} onClose={handleCloseModal} />}
        </div>
      </MainContentLayout>
    </>
  );
};
