import {
  MOXIT_ORGANIZATIONS_FETCHED,
  ORGANIZATION_CREATED,
  ORGANIZATION_FETCHED,
  ORGANIZATION_LISTENER_STARTED,
  ORGANIZATION_SWITCHED,
  ORGANIZATION_SWITCHING,
  ORGANIZATION_UPDATED,
} from './types';

// Import API.
import {
  createOrganization,
  getAllOrganizations,
  organizationOnListen,
  updateOrganization,
} from '../../api/firebase/organizations';
import { updateUserProfile } from '../../api/firebase/users';
import { getOrganizationSetupState } from '../reducers/organizations';

export const organizationListenerStarted = () => ({
  type: ORGANIZATION_LISTENER_STARTED,
});

export const organizationFetched = (organization) => ({
  type: ORGANIZATION_FETCHED,
  organization,
});

export const moxitOrganizationsFetched = (organizations) => ({
  type: MOXIT_ORGANIZATIONS_FETCHED,
  organizations,
});

export const organizationSwitching = () => ({
  type: ORGANIZATION_SWITCHING,
});

export const organizationSwitched = (organizationId) => ({
  type: ORGANIZATION_SWITCHED,
  organizationId,
});

export const organizationCreated = () => ({
  type: ORGANIZATION_CREATED,
});

export const organizationUpdated = () => ({
  type: ORGANIZATION_UPDATED,
});

export { getOrganizationSetupState };

export const startOrganizationListener = (organizationId) => (dispatch) => {
  dispatch(organizationListenerStarted());

  return organizationOnListen(
    organizationId,
    (organization) => {
      if (organization) dispatch(organizationFetched(organization));
    },
    (error) => console.log(error.message)
  );
};

export const getMoxitOrganizations = (limit, last) => async (dispatch) => {
  try {
    const organizations = await getAllOrganizations(limit, last);
    return dispatch(moxitOrganizationsFetched(organizations));
  } catch (error) {
    throw Error(error);
  }
};

export const createMoxitOrganization = (data) => async (dispatch) => {
  try {
    await createOrganization(data);
    dispatch(getMoxitOrganizations());
    return dispatch(organizationCreated());
  } catch (error) {
    throw Error(error);
  }
};

export const updateMoxitOrganization = (data) => async (dispatch) => {
  try {
    await updateOrganization(data.id, data);
    dispatch(getMoxitOrganizations());
    return dispatch(organizationUpdated());
  } catch (error) {
    throw Error(error);
  }
};

export const switchOrganization = (organization, uid) => async (dispatch) => {
  try {
    if (!uid) throw Error('User uid is required');

    dispatch(organizationSwitching());
    await updateUserProfile(uid, {
      impersonatedOrganization: organization?.id ?? null,
      impersonatedDirectorId: organization?.createdBy ?? null,
    });
    return dispatch(organizationSwitched(organization?.id ?? null));
  } catch (error) {
    throw Error(error);
  }
};
