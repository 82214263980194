import { combineReducers } from 'redux';
// Import action.
import { USER_SIGNED_OUT } from '../actions/types';

// Import reducers
import { contactsReducer as contacts } from '../../contacts/contactsRedux';
import { marketplaceReducer as marketplace } from '../../integrations/marketplace/marketplaceRedux';
import { userflowReducer as userflow } from '../../integrations/userflow/userflowRedux';
import { networksReducer as networks } from '../../networks/networksRedux';
import { segmentReducer as segment } from '../../segment/segmentRedux';
import { studentsReducer as students } from '../../students/studentsRedux';
import { timecardsReducer as timecards } from '../../timecards';
import { transactionsReducer as transactions } from '../../transactions/transactionsRedux';
import devices from './devices';
import invitations from './invitations';
import layout from './layout';
import locations from './locations';
import { onboardingReducer as onboarding } from './onboarding';
import organizations from './organizations';
import permissions from './permissions';
import roles from './roles';
import rooms from './rooms';
import staff from './staff';
import stats from './stats';
import user from './user';
import users from './users';

// Billing
import {
  ajaxCallsInProgress,
  charges,
  employeesList,
  invoiceEdit,
  invoices,
  paymentDetails,
  paymentEmployees,
} from './billing';

const combinedReducers = combineReducers({
  contacts,
  user,
  users,
  permissions,
  roles,
  students,
  organizations,
  locations,
  rooms,
  staff,
  layout,
  devices,
  networks,
  transactions,
  segment,
  stats,
  invitations,
  userflow,
  ajaxCallsInProgress,
  employeesList,
  paymentDetails,
  paymentEmployees,
  invoices,
  charges,
  invoiceEdit,
  onboarding,
  marketplace,
  timecards,
});

// Handy trick to clear store upon user sign out.
const rootReducer = (state, action) =>
  action.type === USER_SIGNED_OUT ? combinedReducers({}, action) : combinedReducers(state, action);

export default rootReducer;
