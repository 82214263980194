import {
  USER_PROFILE_FETCHED,
  USER_PROFILE_UPDATED,
  USER_SAVE_SIGNUP_DATA,
  USER_SIGNED_IN,
  USER_SIGNED_OUT,
  USER_SIGNED_UP,
} from '../actions/types';

const initialState = {
  signupData: {},
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_SIGNED_IN:
      return { ...state, ...action.user };
    case USER_SIGNED_OUT:
      return { ...initialState };
    case USER_SIGNED_UP:
      return { ...state };
    case USER_SAVE_SIGNUP_DATA:
      return { ...state, signupData: { ...action.signupData } };
    case USER_PROFILE_FETCHED:
      return { ...state, ...action.user };
    case USER_PROFILE_UPDATED:
      return { ...state, ...action.user };
    default:
      return state;
  }
};
export default user;
