import { SIDEBAR_TOGGLED, SLIDER_TOGGLED, SPACE_SECTION_TOGGLED, TIME_SECTION_TOGGLED } from '../actions/types';

const initialState = {
  sliderOpen: false,
  isSidebarOpen: true,
  isTimeSectionActive: false,
  isSpaceSectionActive: false,
};

const layout = (state = initialState, action) => {
  switch (action.type) {
    case SLIDER_TOGGLED:
      return {
        ...state,
        sliderOpen: action.sliderOpen,
      };

    case SIDEBAR_TOGGLED:
      return {
        ...state,
        isSidebarOpen: action.isSidebarOpen,
      };

    case TIME_SECTION_TOGGLED:
      return {
        ...state,
        isTimeSectionActive: action.isTimeSectionActive,
      };

    case SPACE_SECTION_TOGGLED:
      return {
        ...state,
        isSpaceSectionActive: action.isSpaceSectionActive,
      };

    default:
      return state;
  }
};

export default layout;
