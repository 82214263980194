import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDownloadFiles, useGetFiles } from '@wonderschool/file-service-client';

import {
  ArchiveIcon,
  Badge,
  Button,
  ColorPaletteEnum,
  ColumnConfig,
  DataTable,
  Dropdown,
  InformationalBanner,
  LinkOutIcon,
  showToast,
  ToastTypeEnum,
  WidgetSizeEnum,
} from '@wonderschool/common-base-ui';
import { SortOrder } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../helpers/utils';

import { logError } from '../../rollbar';
import { renderDate } from '../../utils/date';

import { FileCategory, FileDocument, MalwareStatus } from '@wonderschool/common-base-types';
import { QueryCondition } from '@wonderschool/file-service-client/dist/file/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { updateDocument } from '../../api/firebase/firestore';
import mimeTypes from '../../helpers/mimeTypes';
import { useUser } from '../../hooks/useUser';
import AssignFileCategoryModal from './modals/AssignFileCategoryModal';
import DeleteFileModal from './modals/DeleteFileModal';
import SharedWithModal from './modals/SharedWithModal';

interface IOrderBy {
  field: string;
  direction: SortOrder;
}

interface IDefaultFilter {
  limit: 10;
  conditions?: QueryCondition[];
  orderBy: IOrderBy[];
}

const DEFAULT_FILTER: IDefaultFilter = {
  limit: 10,
  conditions: [
    {
      field: 'fileCategory',
      operator: 'in',
      value: [FileCategory.FORM, FileCategory.PARENT_HANDBOOK],
    },
  ],
  orderBy: [
    {
      field: 'createdAt',
      direction: 'desc',
    },
  ],
};

interface IStaffDocumentsTable {
  hideBanner?: boolean;
  hideActions?: boolean;
  onRowSelect: (selectedDocuments: FileDocument[]) => void;
  selectedDocumentIds?: string[];
}

const paths = {
  files: (fileId: string) => `files/${fileId}`,
};

const mapMimeTypeToName = (mime: string) => {
  switch (mime) {
    case mimeTypes.PNG:
    case mimeTypes.JPEG:
      return 'Image';
    case mimeTypes.PDF:
      return 'PDF';
    case mimeTypes.DOC:
    case mimeTypes.DOCX:
      return 'Word';
    case mimeTypes.XLS:
    case mimeTypes.XLSX:
      return 'Spreadsheet';
    default:
      return 'Text';
  }
};

const StaffDocumentsTable: React.FC<IStaffDocumentsTable> = ({
  onRowSelect,
  hideBanner = false,
  hideActions = false,
  selectedDocumentIds = [],
}) => {
  const { t } = useTranslation();
  const { isOrganizationAdmin, isLocationAdmin, user, organization } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters] = useState<IDefaultFilter>(DEFAULT_FILTER);
  const [selectedRows, setSelectedRows] = useState<FileDocument[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileDocument | null>(null);
  const [fileToDelete, setFileToDelete] = useState<FileDocument | null>(null);
  const [fileToAssignCategory, setFileToAssignCategory] = useState<FileDocument | null>(null);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorContent, setErrorContent] = useState({
    header: '',
    message: '',
  });
  const { parentHandbook: isParentHandbookEnabled } = useFlags();

  const openFlyaway = useCallback(
    (row: FileDocument) => {
      setFileToAssignCategory(row);
      setIsModalOpen(true);
    },
    [setFileToAssignCategory, setIsModalOpen]
  );

  const findFileCategory = useCallback(
    (fileCategory: FileCategory) => {
      if (!fileCategory) return '-';
      if (fileCategory === FileCategory.PARENT_HANDBOOK) {
        return <Badge label={t(`common.fileCategory.${fileCategory}`)} color={ColorPaletteEnum.BLUE} />;
      } else {
        return '-';
      }
    },
    [t]
  );

  const {
    data,
    isLoading,
    isError: isGetFilesError,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch: refetchFiles,
  } = useGetFiles(filters);

  const { downloadFiles, error: downloadError } = useDownloadFiles();

  useEffect(() => {
    if (!isGetFilesError && !downloadError) return;

    setErrorOccurred(true);

    logError('Documents Error: ', {
      errorType: isGetFilesError ? 'fetch' : 'download',
      message: error?.message || downloadError?.message || '',
    });

    if (isGetFilesError) {
      setErrorContent({
        header: t('Error occurred while fetching documents'),
        message: error?.message || '',
      });
    } else if (downloadError) {
      setErrorContent({
        header: t('Error occurred while downloading documents'),
        message: downloadError?.message || '',
      });
    }
  }, [isGetFilesError, downloadError, error, setErrorOccurred, t]);

  const tableData: (FileDocument & Record<string, unknown>)[] = useMemo(() => {
    return data?.pages?.flatMap((page: any) => page.documents) ?? [];
  }, [data]);

  useEffect(() => {
    if (tableData.length && selectedDocumentIds.length && !selectedRows.length) {
      setSelectedRows(tableData.filter((doc) => selectedDocumentIds.includes(doc.id)));
    }
  }, [selectedDocumentIds, isLoading, tableData, selectedRows]);

  const onChangeSelectedRows = (selectedRows: FileDocument[]) => {
    setSelectedRows(selectedRows);
    onRowSelect?.(selectedRows);
  };

  const handleView = useMemo(
    () => (row: FileDocument) => {
      if (row.fileStatus !== MalwareStatus.CLEAN) return;
      downloadFiles([row.id]);
    },
    [downloadFiles]
  );

  const handleAssignCategory = async (category: FileCategory) => {
    if (!fileToAssignCategory) return;
    try {
      const existingParentHandbooks = tableData.filter((doc) => doc.fileCategory === 'parent-handbook');
      if (existingParentHandbooks) {
        Promise.all(
          existingParentHandbooks.map(async (handbook) => {
            await updateDocument({
              path: paths.files(handbook.id),
              data: { fileCategory: 'form' },
            });
          })
        );
      }
      await updateDocument({
        path: paths.files(fileToAssignCategory.id),
        data: { fileCategory: category },
      });
      refetchFiles();
      showToast(ToastTypeEnum.Success, t('documents.staff.categoryUpdated'));
    } catch (error) {
      logError('Error updating file category: ', error, {
        user: user?.uid,
        organization: organization?.id,
      });
      showToast(ToastTypeEnum.Error, t('documents.staff.errorUpdatingCategory'));
    } finally {
      setIsModalOpen(false);
    }
  };

  const columns = useMemo(() => {
    const defaultColumns: ColumnConfig<FileDocument & Record<string, unknown>>[] = [
      {
        fieldName: 'name',
        label: t('common.name'),
        renderCell: (row: FileDocument & Record<string, unknown>) => {
          const fileStatus = row.fileStatus;
          if (fileStatus !== MalwareStatus.CLEAN) return row.name;
          return (
            <>
              {/* Mobile Text */}
              <span className="block md:hidden" title={row.name}>
                {row.name}
              </span>

              {/* Desktop Link */}
              <a
                href={`#${row.id}`}
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  downloadFiles([row.id]);
                }}
                data-testid="docs-name"
                className="hidden max-w-[22rem] overflow-hidden text-ellipsis whitespace-nowrap md:inline-block"
                title={row.name}
              >
                {row.name}
              </a>
            </>
          );
        },
      },
      {
        fieldName: 'updatedAt',
        label: t('Uploaded by'),
        renderCell: (row: FileDocument & Record<string, unknown>) => {
          const updatedBy = row.isAdmin ? t('Wonderschool') : row.updatedBy?.displayName;

          return (
            <div className="flex flex-col">
              <div className="text-sm font-medium">{updatedBy}</div>
            </div>
          );
        },
      },
      {
        fieldName: 'file.updatedAt',
        label: t('common.dateModified'),
        renderCell: (row: FileDocument & Record<string, unknown>) => {
          const date = renderDate(row.updatedAt, 'MMM DD, YYYY');
          const fileStatus = row.fileStatus;
          return (
            <div className="flex flex-col">
              <div className="text-sm font-medium">{date}</div>
              <div className="text-xs text-gray-500" data-testid="file-status">
                {t('common.status')}: {capitalize(fileStatus)}
              </div>
            </div>
          );
        },
      },
      {
        fieldName: 'fileAction',
        label: t('Type'),
        renderCell: (val: FileDocument & Record<string, unknown>) => {
          return mapMimeTypeToName(t(val.file.mimetype as string));
        },
      },
      {
        fieldName: 'fileCategory',
        label: t('Category'),
        renderCell: (val: FileDocument & Record<string, unknown>) => {
          return findFileCategory(val.fileCategory);
        },
      },
    ];

    if (!hideActions) {
      defaultColumns.push({
        fieldName: 'actions',
        label: '',
        renderCell: (row: FileDocument & Record<string, unknown>) => {
          const isClean = row.fileStatus === MalwareStatus.CLEAN;
          const items = [
            {
              label: t('Download'),
              onClick: () => handleView(row),
              disabled: !isClean,
              extraClasses: 'whitespace-nowrap',
            },
            {
              label: t('Shared with'),
              onClick: () => setSelectedFile(row),
              disabled: !isClean,
              extraClasses: 'whitespace-nowrap',
            },
          ];

          if (isOrganizationAdmin || isLocationAdmin) {
            items.push({
              label: t('Delete'),
              onClick: () => setFileToDelete(row),
              disabled: false,
              extraClasses: 'whitespace-nowrap',
            });

            if (row.file.mimetype === mimeTypes.PDF && isParentHandbookEnabled) {
              items.push({
                label: t('documents.staff.changeCategory'),
                onClick: () => openFlyaway(row),
                disabled: !isClean,
                extraClasses: 'whitespace-nowrap',
              });
            }
          }

          const buttons = [
            <Button
              key="view"
              primary
              label={t('Download')}
              onClick={() => handleView(row)}
              disabled={!isClean}
              size={WidgetSizeEnum.X_SMALL}
              extraClasses="w-full"
            />,
            <Button
              key="share"
              postIcon={<LinkOutIcon className="size-4" />}
              label={t('Shared with')}
              onClick={() => setSelectedFile(row)}
              disabled={!isClean}
              size={WidgetSizeEnum.X_SMALL}
              extraClasses="w-full"
            />,
          ];

          if (isOrganizationAdmin || isLocationAdmin) {
            buttons.push(
              <Button
                key="delete"
                label={t('Delete')}
                postIcon={<ArchiveIcon className="size-4" />}
                onClick={() => setFileToDelete(row)}
                size={WidgetSizeEnum.X_SMALL}
                extraClasses="w-full bg-red-500 text-white ring-none ring-0 border-none"
              />
            );

            if (row.file.mimetype === mimeTypes.PDF && isParentHandbookEnabled) {
              buttons.push(
                <Button
                  key="change-category"
                  label={t('documents.staff.changeCategory')}
                  onClick={() => openFlyaway(row)}
                  disabled={!isClean}
                  size={WidgetSizeEnum.X_SMALL}
                  extraClasses="w-full"
                />
              );
            }
          }

          return (
            <>
              <div className="hidden md:block">
                <Dropdown items={items} />
              </div>
              <div className="flex flex-col gap-2 md:hidden">{buttons}</div>
            </>
          );
        },
      });
    }

    return defaultColumns;
  }, [
    t,
    downloadFiles,
    isOrganizationAdmin,
    isLocationAdmin,
    handleView,
    openFlyaway,
    findFileCategory,
    hideActions,
    isParentHandbookEnabled,
  ]);

  return (
    <>
      {errorOccurred && !hideBanner && (
        <InformationalBanner type="error" label={errorContent.header + ' ' + errorContent.message} />
      )}

      {/* modals */}
      <SharedWithModal isOpen={!!selectedFile} selectedFile={selectedFile} closeModal={() => setSelectedFile(null)} />
      <DeleteFileModal
        isModalOpen={!!fileToDelete}
        selectedDocument={fileToDelete}
        closeModal={() => setFileToDelete(null)}
      />
      {isModalOpen && (
        <AssignFileCategoryModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          fileToAssignCategory={fileToAssignCategory as FileDocument}
          handleAssignCategory={handleAssignCategory}
          documents={tableData}
        />
      )}

      <DataTable
        data={tableData}
        columns={columns}
        canSelectAll={true}
        canSelect={true}
        onChangeSelectedRows={onChangeSelectedRows}
        loading={isLoading}
        isSelectionDisabled={(doc) => doc.fileStatus !== MalwareStatus.CLEAN}
      />
      {hasNextPage && (
        <Button
          disabled={isFetchingNextPage}
          label={t('Load more')}
          primary
          onClick={() => fetchNextPage()}
          data-testid="load-more"
        />
      )}
    </>
  );
};

export default StaffDocumentsTable;
